body, html {
    margin: 0;
    padding: 0;
    height: 100%;
    font-family: Times New Roman, serif;
    overflow: hidden;
}

.book {
    display: flex;
    width: 100%;
    height: 100%;
    background-color: #f5f5f5;
}

.cursor {
    display: inline-block;
    width: 1px;
    height: 8px;
    background-color: #000;
    animation: blink 1s infinite;
}

@keyframes blink {
    0% { opacity: 0; }
    49% { opacity: 0; }
    50% { opacity: 1; }
    100% { opacity: 1; }
}

.page {
    width: 70%;
    padding: 40px;
    box-sizing: border-box;
    font-size: 12px;
    line-height: 1.4;
    position: relative;
/*    overflow-y: auto;*/
    background-color: white;
}
.page-number {
    position: absolute;
    bottom: 20px;
    font-size: 14px;
}
.left .page-number {
    right: 40px;
}
.right .page-number {
    left: 40px;
}

.box {
  width: 15px;
  height: 200px;
  background-color: black;
}

.blue-box {
    border-width: 1.5px;
    border-style: solid;
    border-color: #0005f9;
    width: 200px;
    height: 200px;
    padding: 10px;
    margin: 10px 0;
}

#box2 {
  float: left;
}

.black-box {
    width: 150px;
    height: 150px;
    background-color: black;
    margin: 10px auto;
    position: sticky;
}

.container {
    position: absolute;
    display: flex;
    gap: 20px;
    z-index: 10;
    top: 500px;
    left: 600px;
}

.footnote {
    font-size: 10px;
    margin-top: 20px;
    border-top: 1px solid #ccc;
    padding-top: 10px;
}

.right-column {
    position: absolute;
    right: 40px;
    top: 40px;
    width: 20%;
    font-size: 10px;
    text-align: right;
    transform: rotate(180deg);
    writing-mode: vertical-rl;
    line-height: 1.2;
}

.main-content {
    width: 75%;
}

.float-box {
    float: right;
    text-align: left;
    position: relative;
}

.constellation {
    position: relative;
    width: 300px;
    height: 300px;
}
.star {
    position: absolute;
    width: 4px;
    height: 4px;
    background-color: yellow;
    border-radius: 50%;
    box-shadow: 0 0 5px 1px yellow;
}
.line {
    position: absolute;
    background-color: yellow;
    transform-origin: 0 50%;
}

/* Star positions */
.star:nth-child(1) { top: 50px; left: 100px; }
.star:nth-child(2) { top: 80px; left: 150px; }
.star:nth-child(3) { top: 120px; left: 180px; }
.star:nth-child(4) { top: 160px; left: 200px; }
.star:nth-child(5) { top: 200px; left: 180px; }
.star:nth-child(6) { top: 220px; left: 140px; }
.star:nth-child(7) { top: 200px; left: 100px; }
.star:nth-child(8) { top: 160px; left: 80px; }
.star:nth-child(9) { top: 120px; left: 60px; }

/* Lines */
.line:nth-child(10) { top: 52px; left: 102px; width: 52px; height: 1px; transform: rotate(30deg); }
.line:nth-child(11) { top: 82px; left: 152px; width: 42px; height: 1px; transform: rotate(45deg); }
.line:nth-child(12) { top: 122px; left: 182px; width: 42px; height: 1px; transform: rotate(60deg); }
.line:nth-child(13) { top: 162px; left: 202px; width: 42px; height: 1px; transform: rotate(120deg); }
.line:nth-child(14) { top: 202px; left: 182px; width: 52px; height: 1px; transform: rotate(150deg); }
.line:nth-child(15) { top: 222px; left: 142px; width: 52px; height: 1px; transform: rotate(210deg); }
.line:nth-child(16) { top: 202px; left: 102px; width: 62px; height: 1px; transform: rotate(250deg); }
.line:nth-child(17) { top: 162px; left: 82px; width: 52px; height: 1px; transform: rotate(290deg); }
.line:nth-child(18) { top: 122px; left: 62px; width: 142px; height: 1px; transform: rotate(350deg); }